.sidebar {
  height: 100%;
  background-color: $dark-grey;
  box-shadow: 0px 0px 10px 2px black;
  z-index: 40;
  font-size: 20px;
  color: $light-grey;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: all;

  .scrollable {
    overflow: auto;
  }


  & .header {
    width: calc(100% - 40px);
    padding: 20px;
    position: relative;
  }

  & .container {
    padding: 20px;
    border-top: 1px solid $grey;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:last-of-type {
      margin-top: auto;
    }
  }

  & .selectedDrone {
    font-size: 16px;
    position: relative;
  }

  & .menuEntries {
    padding-left: 10px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .menuEntry {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 10px;
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        cursor: pointer;
        background-color: rgb(154, 154, 154, 0.3);
      }

      & .extraLabel {
        font-size: 12px;
        color: lightgrey;
      }
    }

    & .languageEntry {
      img {
        height: 20px;
      }
    }
  }

  & .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &.opened {
    min-width: 300px;
    width: 20%;

    & .logo {
      width: 40%;
    }
  }

  &.closed {
    width: 100px;
    font-size: 16px;

    & .logo {
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    & .menuEntries {
      padding: 0px;
    }

    & .closeButton {
      display: none;
    }

    & .menuEntry {
      justify-content: center;
      overflow: hidden;
      padding: 5px;
      font-size: 12px;
      gap: 5px;

      & .extraLabel, svg {
        display: none;
      }
    }

    & .languageEntry {
      justify-content: center;

      & span, svg {
        display: none;
      }
    }

    & .container {
      padding: 10px;
    }

    & h3 {
      display: none;
    }

    & .menuDropdown {
      font-size: 24px;
      justify-content: center;

      & .label {
        display: none;
      }
    }

    & footer {
      flex-direction: column;
    }
  }
  
  & h3 {
    margin: 0;
    font-size: 16px;
    color: white;
  }

  & footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}