.toggleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-left: -10px;

  & .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
    margin-top: 2px;
    margin-left: 10px;  
  }
  
  & .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: .4s;
    border-radius: 34px;
    border: 1px solid #3c4044;
  }
  
  & .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: rgba(#fff, 0.6);
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: rgba(149, 255, 0, 0.3);
  }
  
  input:checked + .slider:before {
    transform: translateX(31px);
    background-color: rgb(150, 245, 17);
  }
  
  .toggle-label {
    font-size: 16px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

}
