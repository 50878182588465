body {
  font-family: Varela;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menuDropdown {
  color: $light-grey;
  display: flex;
  border-radius: 10px;
  align-items: center;
  user-select: none;
  padding: 6px;
  gap: 10px;

  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  svg {
    fill: currentColor;
  }
}

.menuActive {
  color: rgb(147, 221, 43);
  background-color: rgba(149, 255, 0, 0.15);

  &:hover {
    color: rgb(150, 245, 17);
    background-color: rgba(149, 255, 0, 0.3);
  }
}

.disabled {
  color: rgba(255, 255, 255, 0.1);

  &:hover {
    color: rgba(255, 255, 255, 0.1);
    background-color: $dark-grey;
    cursor: default;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 40;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
}

button {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px; 
  border: 1px solid #3c4044;
  color: $light-grey;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    background-color: #3c4044;
    color: white;
  }
}

* {
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #717171;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  
  scrollbar-width: thin;
  scrollbar-color: #717171 #2c2c2c;
}

.closeButton {
  padding: 5px;
  border-radius: 50%;
  margin: 0;
  line-height: 0;
  background: none;
  border: none;
  color: white;

  &:hover {
    color: rgb(147, 221, 43);
    background-color: rgba(149, 255, 0, 0.176);
    cursor: pointer;
  }

  svg {
    fill: currentColor;
  }
}