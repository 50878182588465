.bottomMenu {
  z-index: 50;
  float: left;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out;

  &.opened {
    transform: translateY(0);
  }

  & .resizer {
    width: 100%;
    height: 10px;
    user-select: none;

    &:hover {
      cursor: ns-resize;
    }
  }

  & .shadowArea {
    box-shadow: rgba(0, 0, 0, 0.9) 0px -40px 36px -28px inset;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 100%;

    & svg {
      margin-bottom: 20px;
    }
  }

  & .filters{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 15px;

    & .multipleFiltersContainer{
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & .filterContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 4px;
      font-size: 16px;

      & .clear-icon {
        position: absolute;
        right: 3px;
        padding: 2px;
        border-radius: 50%;
        margin: 0;
        line-height: 0;
        background: none;
        border: none;

        svg {
          margin-bottom: 0;
          scale: 1;
          color: white;
        }
      }

      & .clear-icon:hover {
        background-color: rgba(149, 255, 0, 0.176);
        cursor: pointer;

        svg {
          color: rgb(147, 221, 43);
        }
      }

      & .concreteFilter{
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px; 
        height: 30px;
        width: 180px;
        border: 1px solid #3c4044;
        color: white;
        font-size: 16px;
        padding-left: 10px;

        &:focus{
          border: 1px solid #5d646a;
          outline: none;
        }
      }
    }
  }

  & .filterSection {
    border-bottom: 1px solid $grey;
    
    & .filterContainer {
      padding: 10px;
      padding-bottom: 0;
    }
  }
  

  & .content {
    background-color: $dark-grey;
    width: calc(100% - 1px);
    border-left: 1px solid $grey;
    font-size: 20px;
    color: white;
    pointer-events: all;
    overflow-y: auto;

    & span {
      padding: 10px;
    }
  }

  button {
    height: 30px;
  }

  svg {
    fill: currentColor;
    color: white;
    scale: 1.2;
    color: #ccc;
    pointer-events: all;
    padding-top: 3px;

    &:hover {
      color: white;
      cursor: pointer;
    }
  }

  .actionContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    position: relative;

    & button {
      height: 35px;
    }

    .clear-icon {
      height: auto;

      svg {
        padding-top: 1px;
      }
    }
  }

  & .tableBox {
    border-radius: 10px;
    padding: 0;
    margin: 10px;
    overflow: hidden;
  }

  & .droneTable {
    border-spacing: 0;
    border-radius: 10px;
    font-size: 18px;
    color: $light-grey;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    & button {
      font-size: 15px;
      margin: 3px;
      width: 45px;
    }

    & .withIcon {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;

      svg {
        margin-bottom: 0;
      }

      & img {
        height: 30px;
      }
    }

    & th {
      letter-spacing: 2px;
      font-weight: 600;
      font-size: 15px;
    }

    & td, th {
      font-size: 15px;
      text-align: left;
      padding: 5px;
      padding-left: 10px;
    }

    & tr:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    & tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

thead {
  & tr:nth-child(odd) {
    background-color: #3d5c19 !important;
  }
  
  & tr:nth-child(even) {
    background-color: #557c25 !important;
  }
}

.tableContainer {
  padding: 10px;

  & .time {
    color: rgb(154, 154, 154);
    font-size: 14px;
  }

  & .droneTable {
    padding: 0px;
    box-shadow: none;
  }

  & .controls {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;

    & button {
      height: 35px;
      font-size: 18px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      & svg {
        padding-bottom: 5px;
      }
    }
  }
}

.chartContainer {
  & .recharts-surface {
    scale: 1;
  }
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid black;
  color: white;
  font-weight: 300;
  font-size: 16px;
  padding: 10px;
}

input[type="range"] {
  -webkit-appearance: none;
  background-color: rgba(#fff, .2);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  cursor: ew-resize;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    box-shadow: -150rem 0 0 150rem rgba(#fff, 0.6);
  }

  &::-moz-range-thumb {
    border: none;
    width: 0;
    box-shadow: -150rem 0 0 150rem rgba(#fff, 0.2);
  }
}

.legend {
  display: flex;
  justify-content: center;
  column-gap: 10px;

  & h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bottomMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & .recordUpdate {
    font-size: 13px;
    margin-left: auto;
  }
}

.sortableColumn {
  display: flex;
  gap: 5px;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
  
  & .sortControls {
    display: flex;
    flex-direction: column;

    & svg {
      color: rgba(255, 255, 255, 0.2);
      &:last-child {
        margin-top: -10px;
      }
    }

    &.sortAsc {
      & svg:first-child {
        color: white;
      }
    }

    &.sortDesc {
      & svg:last-child {
        color: white;
      }
    }
  }
}