$primary: #61892F;
$secondary: rgb(134, 194, 50);
$dark-grey: #15181a;
$grey: #2b2e31;
$light-grey: #D7D7D7;

@import './components/main.scss';
@import './components/sidebar.scss';
@import './components/bottomMenu.scss';
@import './components/settingsPopup.scss';
@import './common/components.scss'